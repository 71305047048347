import {
  IonLabel,
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  IonInput,
  IonText,
  IonPopover,
  IonSpinner,
} from "@ionic/react";
import "./Login.css";
import { useState, useEffect } from "react";
import Logo from "../../assets/gm_logo.png";
import axios from "axios";
import configs from "../../configs";
import bg from "../../assets/bg.jpg"

import i18n from "../../Translations/i18nConfigs";

const Login = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  const [Validations, setValidations] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [showPopover2, setShowPopover2] = useState(false);
  const [showPopover3, setShowPopover3] = useState(false);
  const [showPopover4, setShowPopover4] = useState(false);
  const [message, setMessage] = useState();
  const [resendEmail, setResendEmail] = useState();
  const [spinner, setSpinner] = useState(false);
  
  //use this if you want to use email instead of username
  // const [data, setData] = useState({
  //   email: "",
  //   password: "",
  // });

  //use this if you want to use username instead of email
  const [data, setData] = useState({
    username: "",
    password: "",
  });

  const LoginAPI = (e) => {
    e.preventDefault();
    setSpinner(true);
    axios
      .post(`${configs.base_url}${configs.api_url}login/`, data)
      .then((response) => {
        localStorage.setItem("access token", response.data.access_token);
        localStorage.setItem("refresh token", response.data.refresh_token);
        localStorage.setItem("email", response.data.user.email);
        localStorage.setItem("first_name", response.data.user.first_name);
        localStorage.setItem("last_name", response.data.user.last_name);
        localStorage.setItem("mob_number", response.data.user.mob_number);
        localStorage.setItem("business_name", response.data.user.business_name);
        setSpinner(false);
        window.location.assign("/Items");
        setShowPopover(true);
      })
      .catch((error) => {
        // console.log(error);
        setSpinner(false);
        const response = error.response.data.non_field_errors[0];
        if (response === "E-mail is not verified.") {
          setShowPopover3(true);
        } else {
          setValidations(error.response.data);
          setShowPopover2(true);
        }
      });
  };

  function emailResend(event) {
    event.preventDefault();

    axios
      .post(`${configs.base_url}${configs.api_url}register/resend-email/`, {
        email: data.email,
      })
      .then((response) => {
        setMessage(i18n.t('login.emailVerificationSent'));
        setShowPopover3(false);
        setShowPopover4(true);
      })
      .catch((error) => {
        // console.log(error);
        setValidations(error.response.data);
        setShowPopover2(true);
      });
  }

  useEffect(() => {
    async function main() {
      localStorage.clear();
    }

    main();
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <IonGrid style={{ padding: "0px" }}>

<IonRow className="secondCol"
      size-md="6"
      style={{
        height: `${isSmallScreen ? "20vh" : "0vh"}`,
      }}></IonRow>


  <IonRow
    style={{
      display: "flex",
      flexWrap: "wrap",
      overflow: `${isSmallScreen ? "scroll" : "hidden"}`,
    }}
  >
    <IonCol
      size="12"
      size-md="6"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: `${isSmallScreen ? "80vh" : "100vh"}`,
        zIndex: "1",
      }}
    >
     
      <IonRow style={{ width: "70%", display: "block", margin: "auto" }}>
        <img
          src={Logo}
          alt="logo"
          style={{
            width: `${isSmallScreen ? "50%" : "30%"}`,
            marginTop: `${isSmallScreen ? "20%" : "0%"}`,
          }}
        ></img>
        <h3 className="txtLogin"> {i18n.t('login.title')}</h3>
        <form onSubmit={LoginAPI}>
          <IonRow>
            <IonCol sizeLg="12" size="12">
            {/* <IonLabel className="txtLabel"> {i18n.t('login.email')}</IonLabel> */}
            <IonLabel className="txtLabel"> {i18n.t('login.username')}</IonLabel>
            {/* uncomment this if you want to use email instead of username */}
                {/* <IonInput
                  required
                  type="email"
                  className="txtField"
                  placeholder={i18n.t('login.emailPlaceholder')}
                  name="email"
                  value={data.email}
                  onIonChange={(event) =>
                    setData({ ...data, email: event.target.value })
                  }
                ></IonInput> */}
                 <IonInput
                  required
                  type="text"
                  className="txtField"
                  placeholder={i18n.t('login.usernamePlaceholder')}
                  name="username"
                  value={data.username}
                  onIonChange={(event) =>
                    setData({ ...data, username: event.target.value })
                  }
                ></IonInput>
                <IonLabel className="txtLabel">{i18n.t('login.password')}</IonLabel>
                <IonInput
                  required
                  type="password"
                  className="txtField"
                  placeholder={i18n.t('login.passwordPlaceholder')}
                  name="password"
                  value={data.password}
                  onIonChange={(event) =>
                    setData({ ...data, password: event.target.value })
                  }
                ></IonInput>
            </IonCol>
           
          </IonRow>
          
          {!spinner && (
                  <IonButton
                    type="submit"
                    expand="block"
                    class="btnLogin"
                    style={{
                      width: "100%",
                      height: "50px",
                      textTransform: "none",
                      marginTop: "20px",
                    }}
                  >
                    {i18n.t('login.title')}
                  </IonButton>
                )}
                {spinner && (
                  <IonButton
                    type="submit"
                    expand="block"
                    class="btnLogin"
                    disabled
                    style={{
                      width: "100%",
                      height: "50px",
                      textTransform: "none",
                      marginTop: "20px",
                    }}
                  >
                    <IonSpinner></IonSpinner>
                  </IonButton>
                )}


<IonPopover
                  className="errorvalidationpopover"
                  isOpen={showPopover2}
                  onDidDismiss={() => setShowPopover2(false)}
                >
                  <ion-content class="ion-padding">
                    {Validations &&
                      Object.keys(Validations).map((fieldName, index) => (
                        <p id="confirmation" key={index.toString()}>
                          {`${fieldName}: ${Validations[fieldName].join(", ")}`}
                        </p>
                      ))}
                  </ion-content>
                </IonPopover>

                <IonPopover
                  className="errorvalidationpopover"
                  isOpen={showPopover3}
                  onDidDismiss={() => setShowPopover3(false)}
                >
                  <ion-content
                    class="ion-padding"
                    style={{ textAlign: "center" }}
                  >
                    {i18n.t('login.emailNotVerified')}{" "}
                    <a onClick={emailResend}>{i18n.t('login.emailLink')}</a> <br /> {i18n.t('login.tryAgain')}
                  </ion-content>
                </IonPopover>

                <IonPopover
                  className="errorvalidationpopover"
                  isOpen={showPopover4}
                  onDidDismiss={() => setShowPopover4(false)}
                >
                  <ion-content
                    class="ion-padding"
                    style={{ textAlign: "center" }}
                  >
                   {message}
                  </ion-content>
                </IonPopover>
              </form>

              <IonText
                style={{
                  display: "block",
                  margin: "auto",
                  marginTop: `${isSmallScreen ? "10%" : "5%"}`,
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                <a href="/ResetPass">{i18n.t('login.forgotPass')}</a>
              </IonText>

              <IonText
                style={{
                  display: "block",
                  margin: "auto",
                  marginTop: `${isSmallScreen ? "10%" : "20%"}`,
                  fontSize: "14px",
                  textAlign: "center",
                  marginBottom: "20px"
                }}
              >
                {i18n.t('login.noAccount')}<a href="/Signup">{i18n.t('login.signup')}</a>
              </IonText>

      </IonRow>
    </IonCol>
    <IonCol
      className="secondCol"
      size-md="6"
      style={{
        height: `${isSmallScreen ? "0vh" : "100vh"}`,
        position: `${isSmallScreen ? "absolute" : "relative"}`,
        top: "0",
        left: "0",
        width: "100%",
        zIndex: "1",
        padding: "0px"
      }}
    ><img src={bg} style={{height: `${isSmallScreen ? "0%" : "100vh"}`,}}></img></IonCol>
  </IonRow>
</IonGrid>
    </>
  );
};

export default Login;
