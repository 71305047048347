import {
  IonButton,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonMenuButton,
  IonPage,
  IonToolbar,
  IonSplitPane,
  IonCard,
  IonText,
  IonIcon,
  IonDatetimeButton,
  IonModal,
  IonLabel,
  IonTextarea,
  IonPopover,
  IonInput,
  IonDatetime,
} from "@ionic/react";

import {
  calendarOutline,
  trash,
  homeOutline,
  chatbubbleOutline,
} from "ionicons/icons";

import "./cart.css";
import axios from "axios";
import configs from "../../configs/index";
import { useState, useEffect } from "react";
import Menu from "../../sidebar/sidebar";
import { useLocation } from "react-router-dom";
//for accessToken
import { useContext } from "react";
import AuthContext from "../../context/Authcontext";

import i18n from "../../Translations/i18nConfigs";

function Items() {
  const { accesstoken } = useContext(AuthContext);

  const location = useLocation();

  const [cart, setCart] = useState();

  const [showPopover, setShowPopover] = useState(false);
  const [showPopover2, setShowPopover2] = useState(false);

  const [message, setMessage] = useState("");

  const [address, setAddress] = useState();

  const [data, setData] = useState({
    request_date: "",
    comment: "",
    address: "",
  });

  const [deliveryDay, setDeliveryDay] = useState(null);

  useEffect(() => {
    async function getProducts() {
      try {
        const response = await axios.get(
          `${configs.base_url}${configs.api_url}cart/mycart/`,
          {
            headers: {
              Authorization: `Bearer ${accesstoken}`,
            },
          }
        );
        setCart(response.data);

        const firstItem = response.data[0];
        const itemDeliveryDay = firstItem?.item?.owner?.delivery;
        setDeliveryDay(itemDeliveryDay);
      } catch (error) {
        console.error(error);
      }
    }

    getProducts();
  }, []);

  const isDeliveryDayEnabled = (dateString) => {
    const date = new Date(dateString);
    const utcDay = date.getUTCDay();

    // Enable the date only if it matches the delivery day
    return utcDay === getDayIndex(deliveryDay);
  };

  // Adjust the getFirstAvailableDate function
  const getFirstAvailableDate = () => {
    if (!deliveryDay) {
      // Default to Sunday if deliveryDay is not available
      return new Date().toISOString().slice(0, 10);
    }

    const today = new Date();
    const dayIndex = getDayIndex(deliveryDay);

    // Find the next available date
    while (today.getUTCDay() !== dayIndex) {
      today.setDate(today.getDate() + 1);
    }

    return today.toISOString().slice(0, 10);
  };

  const getDayIndex = (day) => {
    const dayOfWeekMap = {
      sun: 0,
      mon: 1,
      tue: 2,
      wed: 3,
      thu: 4,
      fri: 5,
      sat: 6,
    };

    return dayOfWeekMap[day];
  };

  useEffect(() => {
    async function getAddress() {
      axios
        .get(`${configs.base_url}${configs.api_url}userdetail/`, {
          headers: {
            Authorization: `Bearer ${accesstoken}`,
          },
        })
        .then((response) => {
          setAddress(response.data.business_address);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    getAddress();
  }, []);

  async function deleteItem(id) {
    axios
      .delete(`${configs.base_url}${configs.api_url}cart/mycart/${id}/`, {
        headers: {
          Authorization: `Bearer ${accesstoken}`,
        },
      })
      .then((response) => {
        setMessage(i18n.t("cart.removeItemSuccess"));
        setShowPopover(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage(i18n.t("cart.removeItemFailed"));
        setShowPopover(true);
      });
  }

  async function goCheckout(event) {
    event.preventDefault();
    if (!data.request_date) {
      setMessage(i18n.t("cart.validDate"));
      setShowPopover2(true);
    } else if (data.request_date) {
      axios
        .post(`${configs.base_url}${configs.api_url}cart/checkout/`, data, {
          headers: {
            Authorization: `Bearer ${accesstoken}`,
          },
        })
        .then((response) => {
          setMessage(i18n.t("cart.checkoutSuccessful"));
          setShowPopover(true);
        })
        .catch((error) => {
          console.error(error);
          if (error.response.request.status === 400) {
            setMessage(error.response.data.message);
            setShowPopover2(true);
          } else {
            setMessage(i18n.t("cart.checkoutFailed"));
            setShowPopover(true);
          }
        });
    }
  }

  return (
    <>
      <IonSplitPane when="sm" contentId="main">
        {/* menu --1st column */}
        <Menu location={location} />

        {/* --2nd column */}
        <div className="ion-page" id="main">
          <IonPage id="main-content">
            <IonContent className="ion-padding">
              {/* menu button that shows only on small screen */}
              <IonToolbar className="toolbar">
                <IonButton fill="clear" slot="start">
                  <IonMenuButton style={{ color: "black" }}></IonMenuButton>
                </IonButton>
              </IonToolbar>
              <IonGrid>
                <IonRow
                  style={{
                    display: "block",
                    margin: "auto",
                    marginLeft: "15px",
                  }}
                >
                  <IonCol size="12">
                    <h3 id="title">{i18n.t("cart.title")}</h3>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12">
                    <IonCard style={{ padding: "15px" }}>
                      <form onSubmit={goCheckout}>
                        <IonGrid>
                          <IonRow>
                            <IonCol>
                              <IonLabel className="label">
                                {i18n.t("cart.date")}
                              </IonLabel>
                              <IonRow
                                style={{
                                  border: "1px solid #E3E3E3",
                                  borderRadius: "3px",
                                  padding: "10px",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                <IonIcon
                                  icon={calendarOutline}
                                  style={{
                                    marginRight: "10px",
                                    fontSize: "25px",
                                    color: "#655E5F",
                                  }}
                                ></IonIcon>
                                <IonDatetimeButton datetime="datetime"></IonDatetimeButton>
                                <IonModal keepContentsMounted={true}>
                                  <IonDatetime
                                    id="datetime"
                                    presentation="date"
                                    value={
                                      data.request_date ||
                                      getFirstAvailableDate()
                                    }
                                    onIonChange={(e) =>
                                      setData({
                                        ...data,
                                        request_date: e.detail.value.slice(
                                          0,
                                          10
                                        ),
                                      })
                                    }
                                    displayFormat="YYYY-MM-DD"
                                    min={new Date().toISOString().slice(0, 10)} // Set min date to today
                                    max={new Date(
                                      new Date().setFullYear(
                                        new Date().getFullYear() + 1
                                      )
                                    )
                                      .toISOString()
                                      .slice(0, 10)} // Set max date to one year from today
                                    isDateEnabled={isDeliveryDayEnabled} // Enable only the dates corresponding to the available delivery day
                                    required
                                  ></IonDatetime>
                                </IonModal>
                              </IonRow>
                            </IonCol>
                          </IonRow>

                          <IonRow>
                            <IonCol>
                              <IonLabel className="label">
                                <IonIcon
                                  icon={chatbubbleOutline}
                                  style={{
                                    marginRight: "5px",
                                    fontSize: "17px",
                                    color: "#655E5F",
                                  }}
                                ></IonIcon>
                                {i18n.t("cart.comments")}
                              </IonLabel>
                              <IonRow
                                style={{
                                  border: "1px solid #E3E3E3",
                                  borderRadius: "3px",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                <IonTextarea
                                  rows={2}
                                  autoGrow
                                  onIonChange={(e) =>
                                    setData({
                                      ...data,
                                      comment: e.target.value,
                                    })
                                  }
                                ></IonTextarea>
                              </IonRow>
                            </IonCol>
                          </IonRow>

                          <IonRow>
                            <IonCol>
                              <IonLabel className="label">
                                <IonIcon
                                  icon={homeOutline}
                                  style={{
                                    marginRight: "5px",
                                    fontSize: "17px",
                                    color: "#655E5F",
                                  }}
                                ></IonIcon>{" "}
                                {i18n.t("cart.address")}
                              </IonLabel>
                              <IonRow
                                style={{
                                  border: "1px solid #E3E3E3",
                                  borderRadius: "3px",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                <IonInput
                                  disabled
                                  type="text"
                                  value={address}
                                  onIonChange={(e) =>
                                    setData({
                                      ...data,
                                      address: e.target.value,
                                    })
                                  }
                                ></IonInput>
                              </IonRow>
                            </IonCol>
                          </IonRow>

                          <IonPopover
                            isOpen={showPopover}
                            onDidDismiss={() => {
                              setShowPopover(false);
                              window.location.reload();
                            }}
                          >
                            <ion-content class="ion-padding">
                              {message}
                            </ion-content>
                          </IonPopover>

                          <IonPopover
                            isOpen={showPopover2}
                            onDidDismiss={() => {
                              setShowPopover2(false);
                            }}
                          >
                            <ion-content class="ion-padding">
                              {message}
                            </ion-content>
                          </IonPopover>

                          <IonRow>
                            <IonCol>
                              {/* map to populate the items on the cart */}
                              {cart?.map((item, index) => (
                                <IonRow
                                  key={item.id}
                                  style={{
                                    borderBottom: "1px solid #E3E3E3",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <IonCol size="0" sizeLg="2.4">
                                    <img
                                      alt="Food"
                                      style={{ width: "50px" }}
                                      src={item.item.image}
                                    />
                                  </IonCol>
                                  <IonCol sizeSm="3" sizeLg="2.4">
                                    <IonText className="label">
                                      {item.item.name}
                                    </IonText>
                                  </IonCol>
                                  <IonCol sizeSm="3" sizeLg="2.4">
                                    <IonText>{item.quantity}</IonText>
                                    <IonText>{item.item.units}</IonText>
                                  </IonCol>
                                  <IonCol sizeLg="2.4">
                                    <u>€{item.price}</u>
                                  </IonCol>
                                  <IonCol sizeSm="3" sizeLg="2.4">
                                    <IonIcon
                                      onClick={() => deleteItem(item.id)}
                                      icon={trash}
                                      color="danger"
                                      style={{
                                        marginRight: "10px",
                                        fontSize: "25px",
                                        color: "#655E5F",
                                        cursor: "pointer",
                                      }}
                                    ></IonIcon>
                                  </IonCol>
                                </IonRow>
                              ))}
                              <IonRow class="ion-justify-content-end">
                                <IonCol size="12" sizeLg="3">
                                  <IonButton
                                    class="btnOrder"
                                    type="submit"
                                    expand="full"
                                    style={{
                                      textTransform: "none",
                                      width: "100%",
                                    }}
                                  >
                                    {i18n.t("cart.placeOrder")}
                                  </IonButton>
                                </IonCol>
                              </IonRow>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </form>
                    </IonCard>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonContent>
          </IonPage>
        </div>
      </IonSplitPane>
    </>
  );
}

export default Items;
