import React from "react";
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonRouterLink,
  IonIcon,
  IonRow,
  IonFooter,
  IonCol,
} from "@ionic/react";
import {
  cartOutline,
  documentOutline,
  basketOutline,
  settingsOutline,
  helpCircleOutline,
  personCircleOutline,
  logOutOutline,
} from "ionicons/icons";
import configs from "../configs";
import axios from "axios";
//for accessToken
import { useContext } from "react";
import AuthContext from "../context/Authcontext";

import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import i18n from "../Translations/i18nConfigs";

function Menu(props) {
  const { accesstoken } = useContext(AuthContext);
  const [infos, setInfos] = useState("");

  async function getInfo() {
    axios
      .get(`${configs.base_url}${configs.api_url}userdetail/`, {
        headers: {
          Authorization: `Bearer ${accesstoken}`,
        },
      })
      .then((response) => {
        setInfos(response?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getInfo();
  }, []);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isActive = (path) => {
    return props.location.pathname === path;
  };

  const activeStyle = {
    color: "white",
    background: "rgba(35, 168, 242, 0.71)",
  };
  const activeIcon = { color: "white" };

  return (
    <IonMenu
      contentId="main-content"
      style={{ width: `${isSmallScreen ? "100%" : "20%"}` }}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle style={{ textAlign: "center" }}>
            <b>{infos?.business_name}</b>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRouterLink routerLink="/Cart">
          <IonRow
            className="rowMenu"
            style={isActive("/Cart") ? activeStyle : null}
          >
            <IonIcon
              icon={cartOutline}
              style={{
                marginRight: "10px",
                fontSize: "25px",
                color: "#655E5F",
              }}
            ></IonIcon>
            {i18n.t('sidebar.cart')}
          </IonRow>
        </IonRouterLink>
        <IonRouterLink routerLink="/Transactions">
          <IonRow
            className="rowMenu"
            style={isActive("/Transactions") ? activeStyle : null}
          >
            <IonIcon
              icon={documentOutline}
              style={{
                marginRight: "10px",
                fontSize: "25px",
                color: "#655E5F",
              }}
            ></IonIcon>
            {i18n.t('sidebar.transactions')}
          </IonRow>
        </IonRouterLink>
        <IonRouterLink routerLink="/Items">
          <IonRow
            className="rowMenu"
            style={isActive("/Items") ? activeStyle : null}
          >
            <IonIcon
              icon={basketOutline}
              style={{
                marginRight: "10px",
                fontSize: "25px",
                color: "#655E5F",
              }}
            ></IonIcon>
            {i18n.t('sidebar.items')}
          </IonRow>
        </IonRouterLink>
        {/* <IonRow
          className="rowMenu"
          style={isActive("/Settings") ? activeStyle : null}
        >
          <IonIcon
            icon={settingsOutline}
            style={{
              marginRight: "10px",
              fontSize: "25px",
              color: "#655E5F",
            }}
          ></IonIcon>
          Settings
        </IonRow>
        <IonRow
          className="rowMenu"
          style={isActive("/Help") ? activeStyle : null}
        >
          <IonIcon
            icon={helpCircleOutline}
            style={{
              marginRight: "10px",
              fontSize: "25px",
              color: "#655E5F",
            }}
          ></IonIcon>
          Help
        </IonRow> */}
      </IonContent>
      <IonFooter>
      <IonRouterLink routerLink="/Account">
  <IonRow className="rowMenu" style={isActive("/Account") ? activeStyle : null}>
    <div style={{ display: "flex", alignItems: "center" }}>
      {infos?.profile_photo ? (
        <img
          src={infos?.profile_photo}
          alt="Profile"
          style={{
            marginRight: "0.8rem",
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      ) : (
        <IonIcon icon={personCircleOutline} style={{ marginRight: "19px" }} />
      )}
      <span style={{ alignSelf: "center" }}>
        {infos?.first_name} {infos?.last_name}
      </span>
    </div>
  </IonRow>
</IonRouterLink>

        <IonRouterLink routerLink="/Login">
          <IonRow class="rowMenu">
            <IonIcon
              icon={logOutOutline}
              style={{
                marginRight: "10px",
                fontSize: "25px",
                color: "#655E5F",
              }}
            ></IonIcon>
            {i18n.t('sidebar.logout')}
          </IonRow>
        </IonRouterLink>
      </IonFooter>
    </IonMenu>
  );
}

export default Menu;
