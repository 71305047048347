import {
    IonLabel,
    IonCol,
    IonGrid,
    IonRow,
    IonButton,
    IonInput,
    IonText,
    IonPopover,
    IonSpinner
  } from "@ionic/react";
  import { useState, useEffect } from "react";
  import Logo from "../../../assets/gm_logo.png"
  import axios from "axios";
  import "./reset1.css";
import configs from "../../../configs";

import i18n from "../../../Translations/i18nConfigs";


  const Login = () => {
  
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  
    const [Validations, setValidations] = useState([]);
    const [showPopover, setShowPopover] = useState(false);
    const [message, setMessage] = useState();
    const [showPopover2, setShowPopover2] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [data, setData] = useState({
      email: "",
    });
  
    async function resetPass(event) {
      event.preventDefault();
      setSpinner(true);
      axios
        .post(
          `${configs.base_url}${configs.api_url}password/reset/`,data,
        )
        .then((response) => {
          setSpinner(false);
          setMessage(response.data.detail)
          setShowPopover2(true);
          window.location.assign("/Login");
          
        })
        .catch((error) => {
          console.log(error.response.data);
          setSpinner(false);
          if (error.response.status === 400) {
            setMessage(error.response.data.email)
            setShowPopover2(true);
          } else {
            setMessage(i18n.t('reset1.serverError'))
            setShowPopover2(true);
          }
        });
    }
  
  
    useEffect(() => {
      async function main() {
        localStorage.clear();
      }
  
      main();
      const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 768);
      };
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
     
    }, []);
  
    
    return (
      <>
        <IonGrid style={{ padding: "0px" }}>
          <IonRow>
            <IonCol
              size="12"
              size-md="6"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <div style={{ width: "80%", padding: `${isSmallScreen ? "0%" : "10%"}` }}>
                <img src={Logo} alt="Gastronomia Logo" style={{ width: `${isSmallScreen ? "50%" : "30%"}`, marginTop: `${isSmallScreen ? "40%" : "0%"}` }}></img>
                <h3 className="txtChangePass">{i18n.t('reset1.title')}</h3>
                <IonText id="txt_validation" style={{color: "red", fontFamily: "Poppins", fontSize: "15px"}}></IonText>
                <form onSubmit={resetPass}>
                  <IonLabel className="txtLabel">{i18n.t('reset1.email')}</IonLabel>
                  <IonInput
                    required
                    type="email"
                    className="txtField"
                    placeholder={i18n.t('reset1.emailPlaceholder')}
                    name="email"
                    value={data.email}
                    onIonChange={(event) =>
                      setData({ ...data, email: event.target.value })
                    }
                  ></IonInput>
   {!spinner &&(
                  <IonButton
                    type="submit"
                    expand="block"
                    class="btnLogin"
                    style={{
                      width: "100%",
                      height: "50px",
                      textTransform: "none",
                      marginTop: "30px",
                    }}
                  >
                    {i18n.t('reset1.submit')}
                  </IonButton>
                  )}
                  {spinner &&(
                  <IonButton
                    type="submit"
                    expand="block"
                    class="btnLogin"
                    disabled
                    style={{
                      width: "100%",
                      height: "50px",
                      textTransform: "none",
                      marginTop: "30px",
                    }}
                  >
                    <IonSpinner></IonSpinner>
                  </IonButton>
                  )}
                  <IonPopover
                          className="errorvalidationpopover"
                            isOpen={showPopover2}
                            onDidDismiss={() => setShowPopover2(false)}
                          >
                            <ion-content class="ion-padding">
                          {message}
                            </ion-content>
                          </IonPopover>
                </form>
  
                <IonText
                  style={{
                    display: "block",
                    margin: "auto",
                    marginTop: `${isSmallScreen ? "10%" : "20%"}`,
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  {i18n.t('reset1.goBack')} <a href="/Login">{i18n.t('reset1.login')}</a>
                </IonText>
              </div>
            </IonCol>
            <IonCol
              className="secondCol"
              size-md="6"
              style={{
                height: `${isSmallScreen ? "20%" : "100vh"}`,
                position: `${isSmallScreen ? "absolute" : "relative"}`,
                top: "0",
                left: "0",
                width: "100%",
                zIndex: "1",
              }}
            ></IonCol>
          </IonRow>
        </IonGrid>
      </>
    );
  };
  
  export default Login;
  