import {
  IonButton,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonMenuButton,
  IonPage,
  IonToolbar,
  IonSearchbar,
  IonSplitPane,
  IonCard,
  IonItem,
  IonLabel,
  IonSelectOption,
  IonSelect,
  IonModal,
  IonHeader,
  IonButtons,
  IonTitle,
  IonIcon,
  IonSpinner,
  IonPopover,
  IonDatetime,
  IonDatetimeButton,
} from "@ionic/react";

import { calendarOutline } from "ionicons/icons";

import "./transactions.css";
import { React, useState, useEffect, useRef } from "react";
import { downloadOutline, archiveOutline } from "ionicons/icons";
import axios from "axios";
import configs from "../../configs/index";
import Menu from "../../sidebar/sidebar";
import { useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
//for accessToken
import { useContext } from "react";
import AuthContext from "../../context/Authcontext";

import { FileOpener } from "@ionic-native/file-opener";
import { File } from "@ionic-native/file";
import { isPlatform } from "@ionic/react";

import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";

import i18n from "../../Translations/i18nConfigs";

function Transactions() {
  const { accesstoken } = useContext(AuthContext);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //modal functions
  const [isOpen, setIsOpen] = useState(false);

  const [transactions, setTransactions] = useState();

  //customer code
  const [customercode, setCustomercode] = useState();

  //for button active state
  const [isActive, setIsActive] = useState("All");

  //sorting of ascending or descending
  const [sortOrder, setSortOrder] = useState("descending");

  //storage of detailed transaction
  const [detailedTransaction, setDetailedTransaction] = useState();

  //spinners
  const [downloadLoading, setDonwloadLoading] = useState(false);
  const [sendEmailLoading, setEmailLoading] = useState(false);

  //popover
  const [showPopover, setShowPopover] = useState(false);
  const [message, setMessage] = useState(false);

  //pdf file
  const [saveFile, setSaveFile] = useState();

  const formData = new FormData();
  formData.append("pdf", saveFile);
  formData.append("transaction", detailedTransaction?.id);

  const activeStyle = {
    color: "white",
    textTransform: "none",
    marginRight: "10px",
  };
  const inactiveStyle = {
    color: "black",
    textTransform: "none",
    marginRight: "10px",
  };

  const location = useLocation();

  async function getTransactions() {
    axios
      .get(
        `${configs.base_url}${configs.api_url}transaction/myorders/?order=${sortOrder}`,
        {
          headers: {
            Authorization: `Bearer ${accesstoken}`,
          },
        }
      )
      .then((response) => {
        setNoProduct(false);
        setTransactions(response.data);
        setIsActive("All");
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    if (isActive === "All") {
      getTransactions();
    }
  }, [sortOrder]);

  //get customer code
  useEffect(() => {
    async function getCustomerCode() {
      axios
        .get(`${configs.base_url}${configs.api_url}userdetail/`, {
          headers: {
            Authorization: `Bearer ${accesstoken}`,
          },
        })
        .then((response) => {
          setCustomercode(response.data.customer_no);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    getCustomerCode();
  }, []);

  async function filter(status) {
    if (status !== "All") {
      axios
        .get(
          `${configs.base_url}${configs.api_url}transaction/myorders/?search=${status}&order=${sortOrder}`,
          {
            headers: {
              Authorization: `Bearer ${accesstoken}`,
            },
          }
        )
        .then((response) => {
          setNoProduct(false);
          setTransactions(response.data);
          setIsActive(status);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  useEffect(() => {
    if (isActive !== "All") {
      filter(isActive);
    }
  }, [sortOrder]);

  async function getDetailedTransaction(id) {
    setIsOpen(false);
    axios
      .get(`${configs.base_url}${configs.api_url}transaction/myorders/${id}`, {
        headers: {
          Authorization: `Bearer ${accesstoken}`,
        },
      })
      .then((response) => {
        setDetailedTransaction(response.data);
        setIsOpen(true);
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const [searchTerm, setSearchTerm] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [noProduct, setNoProduct] = useState(false);

  // //search function
  // async function search() {
  //   if (searchTerm.trim() === "") {
  //     isActive === "All" ? getTransactions() : filter(isActive);
  //     setNoProduct(false);
  //     return;
  //   }

  //   isActive !== "All"
  //     ? setSearchProduct(`${isActive}+${searchTerm}`)
  //     : setSearchProduct(searchTerm);

  //   axios
  //     .get(
  //       `${configs.base_url}${configs.api_url}transaction/myorders/?search=${searchProduct}&order=${sortOrder}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${accesstoken}`,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       setNoProduct(false);

  //       if (response.data.length === 0) {
  //         setNoProduct(true);
  //         setTransactions(response.data);
  //       } else {
  //         setNoProduct(false);
  //         setTransactions(response.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }

  // useEffect(() => {
  //   search();
  // }, [searchTerm]);

  async function sendToEmail(event) {
    event.preventDefault();
    downloadPDF("save");
  }

  useEffect(() => {
    if (saveFile) {
      axios
        .post(
          `${configs.base_url}${configs.api_url}transaction/send-to-email/`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${accesstoken}`,
            },
          }
        )
        .then((response) => {
          setEmailLoading(false);
          setMessage(i18n.t("transaction.poSuccessfullySent"));
          setShowPopover(true);
        })
        .catch((error) => {
          console.log(error.response.data);
          setEmailLoading(false);
          setMessage(i18n.t("transaction.poFailedSent"));
          setShowPopover(true);
        });
    }
  }, [saveFile]);

  async function downloadPDF(choice) {
    if (choice === "download") {
      setDonwloadLoading(true);
    }
    const doc = new jsPDF();

    // Add the Purchase Order title
    doc.setFontSize(18);
    doc.setFillColor(0);
    //title
    doc.text(
      `${i18n.t("transaction.po")} - ${detailedTransaction?.customer.company} `,
      15,
      20
    );
    doc.setFontSize(10);
    doc.text(`${i18n.t("transaction.for")} Gastronomia GmbH`, 15, 25);

    //ordered
    doc.text(`${i18n.t("transaction.ordered")}`, 15, 35);
    doc.setFont("helvetica", "bold");
    doc.text(
      `${new Date(detailedTransaction?.created_at).toLocaleDateString("de", {
        weekday: "short",
        year: "numeric",
        month: "long",
        day: "numeric",
      })}`,
      15,
      40
    );
    doc.setFont("helvetica", "normal");
    //order number
    doc.text(`${i18n.t("transaction.orderNumber")}`, 15, 50);
    doc.setFont("helvetica", "bold");
    doc.text(detailedTransaction?.order_no, 15, 55);
    doc.setFont("helvetica", "normal");

    //ordered by
    doc.text(`${i18n.t("transaction.orderedBy")}`, 15, 65);
    doc.setFont("helvetica", "bold");
    doc.text(
      `${detailedTransaction?.customer.first_name} ${detailedTransaction?.customer.last_name} (${detailedTransaction?.customer.mob_number})`,
      15,
      70
    );
    doc.setFont("helvetica", "normal");

    //requested delivery date
    doc.text(`${i18n.t("transaction.requestedDeliveryDate")}`, 100, 35);
    doc.setFont("helvetica", "bold");
    doc.text(
      `${new Date(
        detailedTransaction?.requested_delivery_date
      ).toLocaleDateString("de", {
        weekday: "short",
        year: "numeric",
        month: "long",
        day: "numeric",
      })}`,
      100,
      40
    );
    doc.setFont("helvetica", "normal");

    //customer number
    doc.text(`${i18n.t("transaction.customerNumber")}`, 100, 50);
    doc.setFont("helvetica", "bold");
    doc.text(
      `${customercode} (${detailedTransaction?.customer.company})`,
      100,
      55
    );
    doc.setFont("helvetica", "normal");

    //delivery address
    doc.text(`${i18n.t("transaction.deliveryAddress")}`, 100, 65);
    doc.setFont("helvetica", "bold");
    doc.text(`${detailedTransaction?.address}`, 100, 70);
    doc.setFont("helvetica", "normal");

    doc.setFontSize(10);
    // Add the table with the purchase order details
    // Group products by category
    const productsByCategory = detailedTransaction?.order.reduce(
      (result, item) => {
        if (!result[item.item_snap.category]) {
          result[item.item_snap.category] = [];
        }
        result[item.item_snap.category].push(item);
        return result;
      },
      {}
    );

    // Flattened data for autoTable
    const orderData = [];

    // Iterate through categories and products
    Object.keys(productsByCategory).forEach((category) => {
      // Add category row
      const boldCategoryRow = [
        {
          content: category,
          colSpan: 6,
          styles: { fontStyle: "bold", textColor: [0, 0, 0] },
        },
      ];
      orderData.push(boldCategoryRow);

      // Add product rows
      productsByCategory[category].forEach((item) => {
        const productRow = [
          item.item_snap.product_id,
          item.item_snap.name,
          item.item_snap.units,
          item.quantity,
          "",
          item.price,
        ];
        orderData.push(productRow);
      });
    });

    // Add summary rows
    const subtotalRow = [
      "",
      "",
      "",
      "",
      i18n.t("transaction.subTotal"),
      detailedTransaction?.item_total,
    ];
    orderData.push(subtotalRow);

    const salesrow = [
      "",
      "",
      "",
      "",
      i18n.t("transaction.salesTax"),
      `${detailedTransaction?.tax_fee}`,
    ];
    orderData.push(salesrow);

    const shippingrow = [
      "",
      "",
      "",
      "",
      i18n.t("transaction.shippingHandling"),
      detailedTransaction?.delivery_fee,
    ];
    orderData.push(shippingrow);

    const other = [
      "",
      "",
      "",
      "",
      i18n.t("transaction.other"),
      `${detailedTransaction?.other_fee}`,
    ];
    orderData.push(other);

    const totalrow = [
      "",
      "",
      "",
      "",
      i18n.t("transaction.total"),
      detailedTransaction?.get_total,
    ];
    orderData.push(totalrow);

    // Set initial y-coordinate for the table
    let tableY = 80;

    // Iterate through chunks of the orderData and add them to the table
    for (let i = 0; i < orderData.length; i += 30) {
      const chunk = orderData.slice(i, i + 30);
      doc.autoTable({
        startY: tableY,
        head: [
          [
            i18n.t("transaction.number"),
            i18n.t("transaction.productName"),
            i18n.t("transaction.unit"),
            i18n.t("transaction.quantityOrdered"),
            i18n.t("transaction.quantityDelivered"),
            i18n.t("transaction.unitPrice"),
          ],
        ],
        body: chunk,
        styles: { cellPadding: 2, fontSize: 9 },
        headStyles: {
          fillColor: [255, 255, 255], // No color for the header
          textColor: [0, 0, 0], // Black text for the header
        },
        columnStyles: {
          0: { textAlign: "center" },
          1: { textAlign: "center" },
          3: { textAlign: "center" },
          4: { textAlign: "center" },
          5: { textAlign: "center" },
        },
      });
      tableY = doc.previousAutoTable.finalY + 10;

      // Add a new page if there are more chunks
      if (i + 30 < orderData.length) {
        doc.addPage();
      }
    }

    doc.setTextColor("black");
    doc.text(
      `${detailedTransaction?.customer.first_name} ${detailedTransaction?.customer.last_name}`,
      115,
      tableY + 30
    );
    doc.text(`${i18n.t("transaction.authBy")}`, 115, tableY + 40);
    doc.text(
      `${new Date(detailedTransaction?.created_at).toLocaleDateString("de", {
        weekday: "short",
        year: "numeric",
        month: "long",
        day: "numeric",
      })}`,
      155,
      tableY + 30
    );
    doc.text(`${i18n.t("transaction.date")}`, 160, tableY + 40);

    if (choice === "download") {
      // Save the document
      let pdfOutput = doc.output();

      if (isPlatform("android")) {
        // for Android device
        if (typeof window !== "undefined" && window.cordova) {
          // Native Android
          setDonwloadLoading(false);
          const directory = "Download";
          const fileName = `${i18n.t("transaction.purchaseOrder1")}${
            detailedTransaction?.order_no
          }_${new Date().getTime()}.pdf`;

          try {
            await Filesystem.mkdir({
              path: directory,
              directory: Directory.External,
            });
          } catch (err) {
            // Ignore "Directory exists" error
            if (err.message !== "Directory exists") {
              console.log("Error creating directory:", err);
            }
          }

          try {
            await Filesystem.writeFile({
              path: `${directory}/${fileName}`,
              data: pdfOutput,
              directory: Directory.External,
              encoding: "utf8",
            });

            setMessage(
              i18n.t("transaction.savedSuccessfully") +
                `${Directory.External}/${directory}/${fileName}`
            );
            setShowPopover(true);
          } catch (err) {
            console.log("Error writing file:", err);
          }
        } else {
          // Web browser on Android device
          console.log("Web browser on Android device");
          const pdfBlob = new Blob([pdfOutput], { type: "application/pdf" });
          const url = URL.createObjectURL(pdfBlob);
          const downloadLink = document.createElement("a");
          downloadLink.href = url;
          downloadLink.download = `Purchase_Order-${
            detailedTransaction?.order_no
          }_${new Date().getTime()}.pdf`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          URL.revokeObjectURL(url);
          setDonwloadLoading(false);
        }
      } else if (isPlatform("ios")) {
        // for iOS device
        if (typeof window !== "undefined" && window.cordova) {
          // Native iOS
          setDonwloadLoading(false);
          console.log("ios device");
          const directory = File.tempDirectory;
          const fileName = `Purchase_Order-${
            detailedTransaction?.order_no
          }_${new Date().getTime()}.pdf`;
          File.writeFile(directory, fileName, pdfOutput, true).then(
            (success) => {
              FileOpener.showOpenWithDialog(
                directory + fileName,
                "application/pdf"
              )
                .then(() => console.log("File opened"))
                .catch((e) => console.log("Error opening file", e));
            },
            (err) => {
              console.log(" writing File error : ", err);
            }
          );
        } else {
          console.log("Web browser on iOS device");
          const pdfBlob = new Blob([pdfOutput], { type: "application/pdf" });
          const url = URL.createObjectURL(pdfBlob);
          const downloadLink = document.createElement("a");
          downloadLink.href = url;
          downloadLink.download = `Purchase_Order-${
            detailedTransaction?.order_no
          }_${new Date().getTime()}.pdf`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          URL.revokeObjectURL(url);
          setDonwloadLoading(false);
        }
      } else {
        // for desktop
        doc.save(
          `Purchase_Order-${
            detailedTransaction?.order_no
          }_${new Date().getTime()}.pdf`
        );
        setMessage(i18n.t("transaction.successfullyDownloaded"));
        setShowPopover(true);
        setDonwloadLoading(false);
      }
    } else {
      setEmailLoading(true);
      // Get the PDF as a binary string
      const pdfData = doc.output("arraybuffer");
      // Convert the binary string to a Blob object
      const pdfBlob = new Blob([pdfData], { type: "application/pdf" });
      // Set the Blob object to the state variable
      setSaveFile(pdfBlob);
    }
  }

  const [data, setData] = useState({
    startDate: "",
    endDate: "",
    showStartModal: false,
    showEndModal: false,
  });

  async function getApprovedPO() {
    const search = isActive != "All" ? `&search=${isActive}` : ``;
    axios
      .get(
        `${configs.base_url}${configs.api_url}transaction/myorders/?start_date=${data.startDate}&end_date=${data.endDate}${search}`,
        {
          headers: {
            Authorization: `Bearer ${accesstoken}`,
          },
        }
      )
      .then((response) => {
        setTransactions(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  useEffect(() => {
    if (data.startDate && data.endDate) {
      getApprovedPO();
    }
  }, [data]);

  return (
    <>
      <IonSplitPane when="sm" contentId="main">
        {/* menu --1st column */}
        <Menu location={location} />

        {/* --2nd column */}
        <div className="ion-page" id="main">
          <IonPage id="main-content">
            <IonPopover
              className="errorvalidationpopover"
              isOpen={showPopover}
              onDidDismiss={() => setShowPopover(false)}
            >
              <ion-content class="ion-padding">{message}</ion-content>
            </IonPopover>
            <IonContent className="ion-padding">
              {/* menu button that shows only on small screen */}
              <IonToolbar className="toolbar">
                <IonButton fill="clear" slot="start">
                  <IonMenuButton style={{ color: "black" }}></IonMenuButton>
                </IonButton>
              </IonToolbar>
              <IonGrid>
                <IonRow>
                  <IonCol size="12">
                    <h3 id="title">{i18n.t("transaction.title")}</h3>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol size="12" sizeLg="4">
                    <IonItem>
                      <IonLabel>{i18n.t("transaction.sortBy")}</IonLabel>
                      <IonSelect
                        placeholder="Select One"
                        onIonChange={(e) => {
                          setSortOrder(e.detail.value);
                          if (isActive === "All") {
                            getTransactions();
                          } else {
                            filter(isActive);
                          }
                        }}
                        value={sortOrder}
                      >
                        <IonSelectOption value="descending" selected>
                          {i18n.t("transaction.latest")}
                        </IonSelectOption>
                        <IonSelectOption value="ascending">
                          {i18n.t("transaction.oldest")}
                        </IonSelectOption>
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                  <IonCol size="12" sizeLg="4">
                    <IonItem>
                      <IonRow size="12" style={{ textAlign: "center" }}>
                        <IonLabel style={{ margin: "auto" }}>
                          {i18n.t("transaction.startDate")}
                        </IonLabel>
                        <IonDatetimeButton
                          style={{ margin: "auto" }}
                          datetime="start"
                          onClick={() =>
                            setData({ ...data, showStartModal: true })
                          }
                        />
                        <IonModal
                          isOpen={data.showStartModal}
                          onDidDismiss={() =>
                            setData({ ...data, showStartModal: false })
                          }
                          keepContentsMounted={true}
                        >
                          <IonDatetime
                            id="start"
                            presentation="date"
                            value={data.startDate}
                            onIonChange={(e) =>
                              setData({
                                ...data,
                                startDate: e.detail.value.slice(0, 10),
                              })
                            }
                            displayFormat="YYYY-MM-DD"
                            required
                          />
                        </IonModal>
                      </IonRow>
                      <IonRow
                        size="12"
                        style={{ textAlign: "center", margin: "auto" }}
                      >
                        <IonLabel style={{ margin: "auto" }}>
                          {i18n.t("transaction.endDate")}
                        </IonLabel>
                        <IonDatetimeButton
                          style={{ margin: "auto" }}
                          datetime="end"
                          onClick={() =>
                            setData({ ...data, showEndModal: true })
                          }
                        />
                        <IonModal
                          isOpen={data.showEndModal}
                          onDidDismiss={() =>
                            setData({ ...data, showEndModal: false })
                          }
                          keepContentsMounted={true}
                        >
                          <IonDatetime
                            id="end"
                            presentation="date"
                            value={data.endDate}
                            onIonChange={(e) =>
                              setData({
                                ...data,
                                endDate: e.detail.value.slice(0, 10),
                              })
                            }
                            displayFormat="YYYY-MM-DD"
                            required
                          />
                        </IonModal>
                      </IonRow>
                    </IonItem>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol size="12" style={{ padding: "0px" }}>
                    <IonSearchbar
                      expand="full"
                      placeholder={i18n.t("transaction.searchOrder")}
                      value={searchTerm}
                      onIonChange={(e) => setSearchTerm(e.detail.value)}
                    ></IonSearchbar>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol size="12">
                    {/* allows scrolling horizontally */}
                    <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                      <IonButton
                        onClick={getTransactions}
                        style={isActive === "All" ? activeStyle : inactiveStyle}
                        class={isActive === "All" ? "active" : "inactive"}
                      >
                        {i18n.t("transaction.all")}
                      </IonButton>
                      <IonButton
                        onClick={() => filter("confirm")}
                        style={
                          isActive === "confirm" ? activeStyle : inactiveStyle
                        }
                        class={isActive === "confirm" ? "active" : "inactive"}
                      >
                        {i18n.t("transaction.confirmed")}
                      </IonButton>
                      <IonButton
                        onClick={() => filter("pending")}
                        style={
                          isActive === "pending" ? activeStyle : inactiveStyle
                        }
                        class={isActive === "pending" ? "active" : "inactive"}
                      >
                        {i18n.t("transaction.pending")}
                      </IonButton>
                      <IonButton
                        onClick={() => filter("delivered")}
                        style={
                          isActive === "delivered" ? activeStyle : inactiveStyle
                        }
                        class={isActive === "delivered" ? "active" : "inactive"}
                      >
                        {i18n.t("transaction.delivered")}
                      </IonButton>
                      <IonButton
                        onClick={() => filter("cancel")}
                        style={
                          isActive === "cancel" ? activeStyle : inactiveStyle
                        }
                        class={isActive === "cancel" ? "active" : "inactive"}
                      >
                        {i18n.t("transaction.cancelled")}
                      </IonButton>
                    </div>
                  </IonCol>
                </IonRow>

                <IonModal
                  isOpen={isOpen}
                  style={{
                    "--width": `${isSmallScreen ? "100%" : "70vw"}`,
                    "--height": `${isSmallScreen ? "100%" : "80vh"}`,
                  }}
                >
                  <IonHeader>
                    <IonToolbar>
                      <IonTitle>
                        {i18n.t("transaction.title")}{" "}
                        {detailedTransaction?.order_no}
                      </IonTitle>
                      <IonButtons slot="end">
                        <IonButton onClick={() => setIsOpen(false)}>
                          {i18n.t("transaction.close")}
                        </IonButton>
                      </IonButtons>
                    </IonToolbar>
                  </IonHeader>
                  <IonRow>
                    <IonCol size="6"></IonCol>
                    <IonCol size="6">
                      {!sendEmailLoading && (
                        <IonButton
                          style={{
                            "--background": "transparent",
                            "--color": "black",
                            float: "right",
                          }}
                          onClick={sendToEmail}
                        >
                          <IonIcon
                            style={{ marginRight: 10 }}
                            icon={archiveOutline}
                          ></IonIcon>
                          {i18n.t("transaction.sendToEmail")}
                        </IonButton>
                      )}

                      {sendEmailLoading && (
                        <IonButton
                          disabled
                          style={{
                            "--background": "transparent",
                            "--color": "black",
                            float: "right",
                          }}
                        >
                          <IonSpinner></IonSpinner>
                        </IonButton>
                      )}
                      {!downloadLoading && (
                        <IonButton
                          style={{
                            "--background": "transparent",
                            "--color": "black",
                            float: "right",
                          }}
                          onClick={() => downloadPDF("download")}
                        >
                          <IonIcon
                            style={{ marginRight: 10 }}
                            icon={downloadOutline}
                          ></IonIcon>
                          {i18n.t("transaction.downloadAsPdf")}
                        </IonButton>
                      )}
                      {downloadLoading && (
                        <IonButton
                          disabled
                          style={{
                            "--background": "transparent",
                            "--color": "black",
                            float: "right",
                          }}
                        >
                          <IonSpinner></IonSpinner>
                        </IonButton>
                      )}
                    </IonCol>
                  </IonRow>
                  <IonContent
                    className="ion-padding contentSize"
                    id="pdf-container"
                  >
                    <IonRow>
                      <IonCol size="12" style={{ textAlign: "left" }}>
                        <p style={{ fontSize: "20px" }}>
                          <b>{`${i18n.t("transaction.po")} - ${
                            detailedTransaction?.customer.company
                          }`}</b>
                          <p
                            style={{ fontSize: "14px", lineHeight: "0px" }}
                          >{`${i18n.t("transaction.for")} Gastronomia GmbH`}</p>
                        </p>
                      </IonCol>
                      <IonCol size="12" sizeLg="6">
                        <p style={{ lineHeight: "0px" }}>{`${i18n.t(
                          "transaction.ordered"
                        )}`}</p>
                        <p style={{ lineHeight: "5px", marginBottom: "30px" }}>
                          <b>
                            {new Date(
                              detailedTransaction?.created_at
                            ).toLocaleDateString("de", {
                              weekday: "short",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                          </b>
                        </p>

                        <p style={{ lineHeight: "0px" }}>{`${i18n.t(
                          "transaction.orderNumber"
                        )}`}</p>
                        <p style={{ lineHeight: "5px", marginBottom: "30px" }}>
                          <b>{detailedTransaction?.order_no}</b>
                        </p>

                        <p style={{ lineHeight: "0px" }}>{`${i18n.t(
                          "transaction.orderedBy"
                        )}`}</p>
                        <p style={{ lineHeight: "5px", marginBottom: "30px" }}>
                          <b>{`${detailedTransaction?.customer.first_name} ${detailedTransaction?.customer.last_name} (${detailedTransaction?.customer.mob_number})`}</b>
                        </p>
                      </IonCol>

                      <IonCol size="12" sizeLg="6">
                        <p style={{ lineHeight: "0px" }}>{`${i18n.t(
                          "transaction.requestedDeliveryDate"
                        )}`}</p>
                        <p style={{ lineHeight: "5px", marginBottom: "30px" }}>
                          <b>
                            {new Date(
                              detailedTransaction?.requested_delivery_date
                            ).toLocaleDateString("de", {
                              weekday: "short",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                          </b>
                        </p>

                        <p style={{ lineHeight: "0px" }}>{`${i18n.t(
                          "transaction.customerNumber"
                        )}`}</p>
                        <p style={{ lineHeight: "5px", marginBottom: "30px" }}>
                          <b>{`${customercode} (${detailedTransaction?.customer.company})`}</b>
                        </p>

                        <p style={{ lineHeight: "0px" }}>{`${i18n.t(
                          "transaction.deliveryAddress"
                        )}`}</p>
                        <p style={{ lineHeight: "5px", marginBottom: "30px" }}>
                          <b>{`${detailedTransaction?.address}`}</b>
                        </p>
                      </IonCol>
                    </IonRow>

                    <IonRow style={{ marginTop: 20, overflowX: "auto" }}>
                      <IonCol>
                        <table className="striped tableStyles">
                          <thead
                            style={{
                              background: "transparent",
                              textAlign: "center",
                            }}
                          >
                            <tr className="trStyles" style={{ color: "black" }}>
                              <th>{i18n.t("transaction.number")}</th>
                              <th>{i18n.t("transaction.productName")}</th>
                              <th>{i18n.t("transaction.unit")}</th>
                              <th>{i18n.t("transaction.quantityOrdered")}</th>
                              <th>{i18n.t("transaction.quantityDelivered")}</th>
                              <th>{i18n.t("transaction.unitPrice")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {detailedTransaction?.order.reduce(
                              (rows, item, index) => {
                                // Add a category row if it's a new category
                                if (
                                  index === 0 ||
                                  detailedTransaction.order[index - 1].item_snap
                                    .category !== item.item_snap.category
                                ) {
                                  rows.push(
                                    <tr
                                      key={`category_${index}`}
                                      style={{
                                        textAlign: "left",
                                        background: "#ddd",
                                      }}
                                    >
                                      <td colSpan="6">
                                        <b style={{ marginLeft: "20px" }}>
                                          {item.item_snap.category}
                                        </b>
                                      </td>
                                    </tr>
                                  );
                                }

                                // Add the item row
                                rows.push(
                                  <tr
                                    key={index}
                                    style={{ textAlign: "center" }}
                                  >
                                    <td>{item.item_snap.product_id}</td>
                                    <td>{item.item_snap.name}</td>
                                    <td>{item.item_snap.units}</td>
                                    <td>{item.quantity}</td>
                                    <td></td>
                                    <td>{item.price}</td>
                                  </tr>
                                );

                                return rows;
                              },
                              []
                            )}
                            {/* Subtotal row */}
                            <tr style={{ textAlign: "center" }}>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>
                                <b>{i18n.t("transaction.subTotal")}</b>
                              </td>
                              <td>{detailedTransaction?.item_total}</td>
                            </tr>
                            {/* Sales Tax row */}
                            <tr style={{ textAlign: "center" }}>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>
                                <b>{i18n.t("transaction.salesTax")}</b>
                              </td>
                              <td>{detailedTransaction?.tax_fee}</td>
                            </tr>
                            {/* Shipping and Handling row */}
                            <tr style={{ textAlign: "center" }}>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>
                                <b>{i18n.t("transaction.shippingHandling")}</b>
                              </td>
                              <td>{detailedTransaction?.delivery_fee}</td>
                            </tr>
                            {/* Other Fees row */}
                            <tr style={{ textAlign: "center" }}>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>
                                <b>{i18n.t("transaction.other")}</b>
                              </td>
                              <td>{detailedTransaction?.other_fee}</td>
                            </tr>
                            {/* Total row */}
                            <tr style={{ textAlign: "center" }}>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>
                                <b>{i18n.t("transaction.total")}</b>
                              </td>
                              <td>{detailedTransaction?.get_total}</td>
                            </tr>
                          </tbody>
                        </table>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="6"></IonCol>
                      <IonCol size="6">
                        <IonRow>
                          <IonCol style={{ textAlign: "center" }}>
                            <p>
                              <u>
                                {detailedTransaction?.customer.first_name}{" "}
                                {detailedTransaction?.customer.last_name}
                              </u>
                            </p>
                            <p>
                              <b>{i18n.t("transaction.authBy")}</b>
                            </p>
                          </IonCol>
                          <IonCol style={{ textAlign: "center" }}>
                            <p>
                              <u>
                                {new Date(
                                  detailedTransaction?.created_at
                                ).toLocaleDateString("de", {
                                  weekday: "short",
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                })}
                              </u>
                            </p>
                            <p>
                              <b>{i18n.t("transaction.date")}</b>
                            </p>
                          </IonCol>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonContent>
                </IonModal>
                <IonRow>
                  {noProduct && (
                    <IonCol style={{ marginTop: "20px" }}>
                      {i18n.t("transaction.noTransactions")} {`"${searchTerm}"`}
                    </IonCol>
                  )}
                  <IonCol size="12" style={{ padding: "0px" }}>
                    {transactions
                      ?.filter((item) => {
                        if (searchTerm === "") {
                          return item;
                        } else if (
                          item.order_no
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                          item.status
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ) {
                          return item;
                        }
                      })
                      .map((item, index) => (
                        <IonCard
                          key={item.id}
                          style={{ padding: "15px", cursor: "pointer" }}
                          onClick={() => getDetailedTransaction(item.id)}
                        >
                          <IonRow>
                            <IonCol
                              size="6"
                              sizeLg="2"
                              className="transactionsTxt"
                            >
                              <b>{item.seller.company}</b>
                            </IonCol>
                            <IonCol
                              size="6"
                              sizeLg="2"
                              className="transactionsTxt"
                              style={{ color: "black" }}
                            >
                              {item.order_no}
                            </IonCol>
                            <IonCol
                              size="6"
                              sizeLg="2"
                              className="transactionsTxt"
                            >
                              {i18n.t("transaction.orderDate")}
                              <br />
                              {item.created_at.slice(0, 10)}
                            </IonCol>
                            <IonCol
                              size="6"
                              sizeLg="2"
                              className="transactionsTxt"
                            >
                              {i18n.t("transaction.time")}
                              <br />
                              {item.created_at.slice(11, 22)}
                            </IonCol>
                            <IonCol
                              size="12"
                              sizeLg="2.5"
                              className="transactionsTxt"
                            >
                              {i18n.t("transaction.reqDeliveryDate")}
                              <br />
                              {item.requested_delivery_date}
                            </IonCol>
                            <IonCol
                              size="12"
                              sizeLg="1.5"
                              className="transactionsTxt"
                              style={{
                                color:
                                  item.status.toLowerCase() === "pending"
                                    ? "#FFA500"
                                    : item.status.toLowerCase() === "cancel"
                                    ? "#FF0000"
                                    : item.status.toLowerCase() === "delivered"
                                    ? "#039F00"
                                    : "blue",
                                textAlign: "right",
                              }}
                            >
                              <b>
                                {item.status.toLowerCase() === "pending"
                                  ? i18n.t("transaction.pending")
                                  : item.status.toLowerCase() === "confirm"
                                  ? i18n.t("transaction.confirmed")
                                  : item.status.toLowerCase() === "cancel"
                                  ? i18n.t("transaction.cancelled")
                                  : item?.status.toLowerCase() === "delivered"
                                  ? i18n.t("transaction.delivered")
                                  : ""}
                              </b>
                            </IonCol>
                          </IonRow>
                        </IonCard>
                      ))}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonContent>
          </IonPage>
        </div>
      </IonSplitPane>
    </>
  );
}

export default Transactions;
